<template>
    <div>
      <employee-annual-leave-list></employee-annual-leave-list>
    </div>
  </template>
  
  <script>
  export default {
  }
  </script>
  